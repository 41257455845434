import Player from '@vimeo/player';

jQuery(document).ready(function () {
    // Build help button behaviour
    $('#help-button').mouseenter(function () {
        if (!$(this).hasClass('active')) {
            $('#full-help-button').addClass(['bg-black', 'dark:bg-white', 'text-white', 'dark:text-black', 'rounded-full']);
            $('#side-help-button').removeClass('hidden');
        }
    }).mouseleave(function () {
        if (!$(this).hasClass('active')) {
            $('#full-help-button').removeClass(['bg-black', 'dark:bg-white', 'text-white', 'dark:text-black', 'rounded-full']);
            $('#side-help-button').addClass('hidden');
        }
    }).click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('#icon-button').html('<i class=\'z-10 fa-solid fa-question text-3xl\'></i>');
            $('#dropdown-help-button').addClass('hidden');
            $(this).addClass(['dark:hover:bg-black', 'hover:bg-white', 'hover:text-black', 'dark:hover:text-white']);
        } else {
            $(this).addClass('active');
            $('#icon-button').html('<i class=\'z-10 fa-solid fa-chevron-down text-3xl\'></i>');
            $('#dropdown-help-button').removeClass('hidden');
            $('#full-help-button').removeClass(['bg-black', 'dark:bg-white', 'text-white', 'dark:text-black', 'rounded-full']);
            $('#side-help-button').addClass('hidden');
            $(this).removeClass(['dark:hover:bg-black', 'hover:bg-white', 'hover:text-black', 'dark:hover:text-white']);
        }
    });

    $('#full-help-button').on('focusout', function () {
        if ($('#help-button').hasClass('active')) {
            $('#help-button').removeClass('active')
                .addClass(['dark:hover:bg-black', 'hover:bg-white', 'hover:text-black', 'dark:hover:text-white']);
            $('#icon-button').html('<i class=\'z-10 fa-solid fa-question text-3xl\'></i>');
        }
    });

    // Build custom Crisp button
    window.CRISP_READY_TRIGGER = function () {
        // set crisp chat available or not
        function checkIfWebsiteAvailable() {
            if (window.$crisp.is('website:available')) {
                $('#available-crisp-chat').removeClass('hidden');
                console.log('website available');
            } else {
                $('#available-crisp-chat').addClass('hidden');
                console.log('website NOT available');
            }
        }

        setTimeout(function() {
            checkIfWebsiteAvailable();
        }, 2000);

        window.$crisp.push(['on', 'website:availability:changed', () => {
            checkIfWebsiteAvailable();
        }]);

        // set unread message count
        function checkIfChatUnreadMessage() {
            var countUnread = window.$crisp.get('chat:unread:count');
            if (window.$crisp.is('chat:closed') && countUnread > 0) {
                $('#notif-crisp-button').removeClass('hidden').text(countUnread);
                console.log('count: ' + window.$crisp.get('chat:unread:count'));
            } else {
                $('#notif-crisp-button').addClass('hidden').text('');
                console.log('chat opened');
            }
        }

        setTimeout(function() {
            checkIfChatUnreadMessage();
        }, 2000);

        window.$crisp.push(['on', 'message:received', () => {
            checkIfChatUnreadMessage();
        }]);

        window.$crisp.push(['on', 'chat:opened', () => {
            console.log('chat opened');
            $('#notif-crisp-button').addClass('hidden').text('');
            $('#dropdown-help-button').addClass('hidden');
        }]);

        window.$crisp.push(['on', 'chat:closed', () => {
            $('#dropdown-help-button').removeClass('hidden');
        }]);
    };

    // Build Calendar Button form
    var programDuration = $('#js-twig-help-datas').data('timing');

    function updateAdvicedRythm() {
        var rythm = $('#select-program-rythm').val();
        var countDays = $('.checkbox-calendar-day').filter(':checked').length;
        var hour = $('#select-hour-rythm').val();

        if (rythm !== null && countDays > 0) {
            var adviceCount = programDuration * 60 / (countDays * 4 * rythm);
            var adviceHours = Math.floor(adviceCount / 60);
            var adviceMinutes = Math.round(adviceCount % 60);
            var advice = '';

            $('#hour-adviced').val(adviceHours);
            $('#minute-adviced').val(adviceMinutes);

            if (adviceHours > 1) {
                if (adviceMinutes > 1) {
                    advice = adviceHours + ' heures et ' + adviceMinutes + ' minutes par jour';
                } else if (adviceMinutes === 1) {
                    advice = adviceHours + ' heures et ' + adviceMinutes + ' minute par jour';
                } else {
                    advice = adviceHours + ' heures par jour';
                }
            } else if (adviceHours === 1) {
                if (adviceMinutes > 1) {
                    advice = adviceHours + ' heure et ' + adviceMinutes + ' minutes par jour';
                } else if (adviceMinutes === 1) {
                    advice = adviceHours + ' heure et ' + adviceMinutes + ' minute par jour';
                } else {
                    advice = adviceHours + ' heure par jour';
                }
            } else {
                if (adviceMinutes > 1) {
                    advice = adviceMinutes + ' minutes par jour';
                } else {
                    advice = adviceMinutes + 'minute par jour';
                }
            }

            $('#advice-rythm').removeClass('hidden');
            $('#advice-rythm span').text(advice);
            if (hour !== null) {
                $('#create-calendar').removeClass('disabled');
            }
        } else {
            $('#advice-rythm').addClass('hidden');
            $('#advice-rythm span').text('');
        }

        if (hour === null) {
            $('#create-calendar').addClass('disabled');
        }
    }

    $('#select-program-rythm').change(function () {
        updateAdvicedRythm();
    });

    $('.checkbox-calendar-day').change(function () {
        updateAdvicedRythm();
    });

    $('#select-hour-rythm').change(function () {
        updateAdvicedRythm();
    });

    // Manage Vimeo player
    const introIframe = $('#intro-iframe');
    const homeworkIframe = $('#homework-iframe');
    var introPlayer = new Player(introIframe);
    var homeworkPlayer = new Player(homeworkIframe);
    $('#intro-close').click(function () {
        introPlayer.pause();
    });
    $('#homework-close').click(function () {
        homeworkPlayer.pause();
    });

});
